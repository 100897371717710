import {
  dncHttps,
  https,
  mtfHttps,
  rmHttps,
  ecmHttps,
  cmmHttps,
  ncoHttps,
  ncotestHttps,
} from '../../utils/config';
import moment from 'moment';
import {
  formatExcelData,
  formatNcoExcelData,
  formatMTFDraftExcelData,
  formatMTFExcelData,
  formatDraftExcelData,
} from '../../utils/helper';
import { MODULE } from '../../utils/constants';

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_IMP = 'GET_DASHBOARD_IMP';
export const GET_DASHBORD_INITIATION = 'GET_DASHBORD_INITIATION';
export const GET_DASHBOARD_ACTIONITEM = 'GET_DASHBOARD_ACTIONITEM';
export const DASHBOARD2_DATA = 'DASHBOARD2_DATA';
export const GET_DASHBOARD2_IMP = 'GET_DASHBOARD2_IMP';
export const GET_DASHBOARD2_COUNT = 'GET_DASHBOARD2_COUNT';
export const DASHBOARD2_CHART2_DATA = 'DASHBOARD2_CHART2_DATA';
export const GET_DASHBOARD2_FILTER = 'GET_DASHBOARD2_FILTER';
export const ACTION_DASHBOARD_DATA = 'ACTION_DASHBOARD_DATA';
export const GET_ACTION_ITEM_FILTER = 'GET_ACTION_ITEM_FILTER';
export const DASHBOARD2_ACTION_CHART2 = 'DASHBOARD2_ACTION_CHART2';
export const SET_TAB_KEY = 'SET_TAB_KEY';
export const GET_ACTION_ITEM_COUNT = 'GET_ACTION_ITEM_COUNT';
export const GET_EFFECTIVENESS_COUNT = 'GET_EFFECTIVENESS_COUNT';
export const GET_IMPLEMENTATION_COUNT = 'GET_IMPLEMENTATION_COUNT';
export const IMPLEMENTATION_CHART_DATA = 'IMPLEMENTATION_CHART_DATA';
export const EFFECTIVENESS_CHART_DATA = 'EFFECTIVENESS_CHART_DATA';


export const getDashboardIni = (formData) => (dispatch) => {
  const role = localStorage.getItem('role');
 
  // hardcode until backend is ready
  // const module = 'Ethics Case Management';
  const module = 'Change Control Module';

  const obj = JSON.stringify(formData);
  if (module === MODULE.rm) {
    rmHttps
      .post('/getDashboard.php/', obj)
      .then(({ data }) => {
        dispatch({
          type: GET_DASHBOARD_DATA,
          data: data,
          initiation_count: {
            ini_count: data.ini_count.ini_sign,
            pr_count: data.pr_count.pr_sign,
            qr_count: data.qr_count.qr_sign,
            qa_count: data.qa_count.qa_sign,
          },
        });
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/getDashboard.php/', obj)
      .then(({ data }) => {
        dispatch({
          type: GET_DASHBOARD_DATA,
          data: data,
          initiation_count: {
            ini_count: data.ini_count.ini_sign,
            pr_count: data.pr_count.pr_sign,
            qr_count: data.qr_count.qr_sign,
            qa_count: data.qa_count.qa_sign,
          },
        });
      })
      .catch((err) => console.log(err));
    return;
  }
  // ecm module
  if (module === MODULE.ecm) {
    ecmHttps
      .post('/getDashboard.php/', obj)
      .then(({ data }) => {
        console.log('***RES', data);
        dispatch({
          type: GET_DASHBOARD_DATA,
          data: data,
          initiation_count: {
            ini_count: data.ini_count.ini_sign,
            pr_count: data.pr_count.pr_sign,
            qr_count: data.qr_count.qr_sign,
            qa_count: data.qa_count.qa_sign,
          },
        });
      })
      .catch((err) => console.log(err));
    return;
  }
  // cmm module
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getDashboard.php/', obj)
      .then(({ data }) => {
        dispatch({
          type: GET_DASHBOARD_DATA,
          data: data,
          initiation_count: {
            ini_count: data.ini_count.ini_sign,
            pr_count: data.pr_count.pr_sign,
            qr_count: data.qr_count.qr_sign,
            qa_count: data.qa_count.qa_sign,
          },
        });
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/getDashboard.php/', obj)
    .then(({ data }) => {
      dispatch({
        type: GET_DASHBOARD_DATA,
        data: data,
        initiation_count: {
          ini_count: data.ini_count.ini_sign,
          pr_count: data.pr_count.pr_sign,
          qr_count: data.qr_count.qr_sign,
          qa_count: data.qa_count.qa_sign,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const getMtfDashboardIni = (formData) => (dispatch) => {
  // const role = localStorage.getItem('role');
  // const username = localStorage.getItem('username');
  // const formData = { username, role, ...dates };

  const obj = JSON.stringify(formData);
  mtfHttps
    .post('/getDashboard.php/', obj)
    .then(({ data }) => {
      console.log('data', data);
      dispatch({
        type: GET_DASHBOARD_DATA,
        data: data,
        initiation_count: {
          ini_count: data.ini_count.ini_sign,
          pr_count: data.r_count.r_sign,
          qa_count: data.a_count.a_sign,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const getDashboardImp = (formData) => (dispatch) => {
  // const role = localStorage.getItem('role');
  // const username = localStorage.getItem('username');
  // const formData = { username, role, ...dates };
  // const module = localStorage.getItem('module');
  const module = 'Change Control Module';

  const obj = JSON.stringify(formData);
  if (module === MODULE.rm) {
    rmHttps
      .post('/getimplementationDashboard.php/', obj)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          const arr_len = data.length;
          const res = data.slice(0, arr_len - 4);
          const counts = data.slice(Math.max(arr_len - 4, -1));
          const total = res.length;

          dispatch({
            type: GET_DASHBOARD_IMP,
            data: res,
            implementation_count: {
              imp_count: counts[0][0],
              pr_imp_count: counts[1][0],
              qr_imp_count: counts[2][0],
              qa_imp_count: counts[3][0],
            },
            total,
          });
        }
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.dnc) {
    dncHttps
      .post('/getimplementationDashboard.php/', obj)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          const arr_len = data.length;
          const res = data.slice(0, arr_len - 4);
          const counts = data.slice(Math.max(arr_len - 4, -1));
          const total = res.length;

          dispatch({
            type: GET_DASHBOARD_IMP,
            data: res,
            implementation_count: {
              imp_count: counts[0][0],
              pr_imp_count: counts[1][0],
              qr_imp_count: counts[2][0],
              qa_imp_count: counts[3][0],
            },
            total,
          });
        }
      })
      .catch((err) => console.log(err));
    return;
  }
  if (module === MODULE.cmm) {
    cmmHttps
      .post('/getimplementationDashboard.php', obj)
      .then(({ data }) => {
        if (Array.isArray(data)) {
          const arr_len = data.length;
          const res = data.slice(0, arr_len - 4);
          const counts = data.slice(Math.max(arr_len - 4, -1));
          const total = res.length;

          dispatch({
            type: GET_DASHBOARD_IMP,
            data: res,
            implementation_count: {
              imp_count: counts[0][0],
              pr_imp_count: counts[1][0],
              qr_imp_count: counts[2][0],
              qa_imp_count: counts[3][0],
            },
            total,
          });
        }
      })
      .catch((err) => console.log(err));
    return;
  }

  https
    .post('/getimplementationDashboard.php', obj)
    .then(({ data }) => {
      if (Array.isArray(data)) {
        const arr_len = data.length;
        const res = data.slice(0, arr_len - 4);
        const counts = data.slice(Math.max(arr_len - 4, -1));
        const total = res.length;

        dispatch({
          type: GET_DASHBOARD_IMP,
          data: res,
          implementation_count: {
            imp_count: counts[0][0],
            pr_imp_count: counts[1][0],
            qr_imp_count: counts[2][0],
            qa_imp_count: counts[3][0],
          },
          total,
        });
      }
    })
    .catch((err) => console.log(err));
};

export const getDashboardNcoIni = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  ncoHttps
    .post('/getDashboard.php/', obj)
    .then(({ data }) => {
      dispatch({
        type: GET_DASHBOARD_DATA,
        data: data,
        initiation_count: {
          ini_count: data.ini_count.ini_sign,
          pr_count: data.pr_count.pr_sign,
          qr_count: data.qr_count.qr_sign,
          qa_count: data.qa_count.qa_sign,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const getDashboardNcoImp = (formData) => (dispatch) => {
  const obj = JSON.stringify(formData);
  ncoHttps
    .post('/getimplementationDashboard.php', obj)
    .then(({ data }) => {
      dispatch({
        type: GET_DASHBOARD_IMP,
        data: data,
        implementation_count: {
          capa_implementation: data[0].capa_implementation,
          capa_closure: data[1].capa_closure,
          capa_retired: data[2].capa_retired,
          check_performed: data[3].check_performed,
        },
        total: data.length,
      });
    })
    .catch((err) => console.log(err));
};
export const getDashboardInitiations = (type, action) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const username = localStorage.getItem('username');
  const from_date = `${moment()
    .startOf('month')
    .format('YYYY-MM-DD')} 00:00:01`;
  const to_date = `${moment().format('YYYY-MM-DD')} 23:59:59`;
  console.log(type, action);
  const requestData = {
    organization,
    from_date,
    to_date,
    type: type,
  };
  if (action == 'my') {
    requestData['username'] = username;
  }

  ncoHttps
    .post('/dashboardLog.php', requestData)
    .then(({ data }) => {
      const role = localStorage.getItem('role');
      const res = data.filter((d) => d.nco_stage === role);
      const excelData = formatNcoExcelData('initiation', res);
      console.log('API response data:', data);
      dispatch({ type: GET_DASHBORD_INITIATION, data: data, excelData });
    })
    .catch((err) => console.log(err));
};
export const getDashboardAction = (type, action) => (dispatch) => {
  const organization = localStorage.getItem('organization');
  const username = localStorage.getItem('username');
  const from_date = `${moment()
    .startOf('month')
    .format('YYYY-MM-DD')} 00:00:01`;
  const to_date = `${moment().format('YYYY-MM-DD')} 23:59:59`;
  const requestData = {
    organization,
    username,
    from_date,
    to_date,
    type,
  };
  if (action == 'my') {
    requestData['username'] = username;
  }
  ncoHttps
    .post('/implementationDashboardLog.php', requestData)
    .then(({ data }) => {
      const role = localStorage.getItem('role');
      const res = data.filter((d) => d.nco_stage === role);
      const excelData = formatNcoExcelData('initiation', res);
      dispatch({ type: GET_DASHBOARD_ACTIONITEM, data: data, excelData });
      // dispatch(getNextAssignee());
    })
    .catch((err) => console.log(err));
};

export const getNcoDashboard2 = (formData) => (dispatch) => {
  const endpoint = '/dashboard/capa_plan/charts.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: DASHBOARD2_DATA,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getActionItemDashboard = (formData) => (dispatch) => {
  const endpoint = '/dashboard/action_item/charts.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: ACTION_DASHBOARD_DATA,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getNcoDashboard2count = (formData) => (dispatch) => {
  const endpoint = '/dashboard/capa_plan/capa_plan_count.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: GET_DASHBOARD2_IMP,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getNcoDashboard2GlobalCount = (formData) => (dispatch) => {
  const endpoint = '/dashboard/capa_plan/capa_count.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: GET_DASHBOARD2_COUNT,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getDashboardActionItemCount = (formData) => (dispatch) => {
  const endpoint = '/dashboard/action_item/action_item_count.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: GET_ACTION_ITEM_COUNT,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const DashboardCAPAPlanCompare = (formData) => async (dispatch) => {
  const endpoint = '/dashboard/capa_plan/chart4.php';
  try {
    const { data } = await ncoHttps.post(endpoint, JSON.stringify(formData));
    return Array.isArray(data) ? data : [data]; 
  } catch (err) {
    console.error('Error fetching CAPA plan data:', err);
    return [];
  }
};


export const getNcoDashboardFilterValue = (formData) => (dispatch) => {
  const endpoint = `/dashboard/capa_plan/get_filters.php?filter_col=${formData}`;
  const request = ncoHttps.get(endpoint);

  request
    .then(({ data }) => {
      dispatch({
        type: GET_DASHBOARD2_FILTER,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};


export const DashboardActionItemCompare = (formData) => async (dispatch) => {
  const endpoint = '/dashboard/action_item/chart4.php';
  try {
    const { data } = await ncoHttps.post(endpoint, JSON.stringify(formData));
    return Array.isArray(data) ? data : [data]; 
  } catch (err) {
    console.error('Error fetching CAPA plan data:', err);
    return [];
  }
};


export const getActionItemDashboardFilterValue = (formData) => (dispatch) => {
  const endpoint = `/dashboard/action_item/get_filters.php?filter_col=${formData}`;
  const request = ncoHttps.get(endpoint);

  request
    .then(({ data }) => {
      dispatch({
        type: GET_ACTION_ITEM_FILTER,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEffectivenessCount = (formData) => (dispatch) => {
  const endpoint = '/dashboard/check_effectiveness/count.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: GET_EFFECTIVENESS_COUNT,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getImplementationCount = (formData) => (dispatch) => {
  const endpoint = '/dashboard/capa_implementation/count.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: GET_IMPLEMENTATION_COUNT,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};



export const getImplementationChart = (formData) => (dispatch) => {
  const endpoint = '/dashboard/capa_implementation/charts.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: IMPLEMENTATION_CHART_DATA,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};

export const getEffectivenessChart = (formData) => (dispatch) => {
  const endpoint = '/dashboard/check_effectiveness/charts.php';

  const request = ncoHttps.post(endpoint, JSON.stringify(formData));

  request
    .then(({ data }) => {
      dispatch({
        type: EFFECTIVENESS_CHART_DATA,
        data: data,
      });
    })
    .catch((err) => console.log(err));
};
